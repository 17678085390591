@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer utilities {


  .secciones {
    @apply flex justify-center items-center mx-auto;
  }

  .btn-projects {
    @apply text-green-fos hover:text-white border border-green-fos bg-white hover:bg-green-fos rounded-full text-base font-medium px-5 py-2.5 text-center me-3 mb-3 duration-300
  }
}